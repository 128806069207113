.popup {
  position: relative;
  background: var(--bg-primary);
  box-shadow: 0 1px 2px var(--shadow-1);
  width: 40vw;
  height: 200px;
  border-radius: 10px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  text-align: center;
}

.popup_header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-bottom: 1px solid var(--bg-third);
  padding: 15px;
}

.popup_message {
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
}

.success_text {
  color: green;
}

.error_text {
  color: var(--error-red);
}
