.friends {
  height: calc(100vh - 56px);
  width: 100%;
  margin-top: 56px;
  display: grid;
  color: var(--color-primary);
}

.friends_left {
  background: var(--bg-primary);
  box-shadow: 0 12px 12px var(--shadow-1);
  padding: 10px;
  position: fixed;
  width: 360px;
  height: 100%;
}

.friends_right {
  background: var(--bg-secondary);
  padding: 2rem;
  position: absolute;
  top: 56px;
  left: 360px;
  right: 0;
  width: calc(100% - 360px);
  min-height: calc(100vh - 56px);
}

.friends_left_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.friends_left_header h3 {
  font-size: 21px;
}

.friends_left .mmenu_item {
  cursor: pointer;
}

.heading_icon {
  position: relative;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
  background: var(--bg-third);
}

.header_icon img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #b0b3b8;
  transform: translateX(-1px);
  object-fit: cover;
}

.header_icon i:hover {
  cursor: default !important;
}

.active_friends {
  background: var(--bg-secondary);
}

.active_friends .small_circle {
  background: var(--light-teal);
}

.active_friends i {
  filter: invert(100%);
}

.active_friends .rArrow {
  display: none;
}

.see_link {
  color: var(--light-teal);
  padding: 5px;
  cursor: pointer;
}

.friends_right_wrap {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bg-third);
}

.friends_right_wrap .flex_wrap {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.req_card {
  background: var(--bg-primary);
  box-shadow: 1px 1px 1px var(--shadow-1);
  width: 210px;
  height: fit-content;
  padding-bottom: 10px;
  border-radius: 10px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.req_card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.req_card button {
  width: 170px !important;
}

@media (max-width: 870px) {
  .friends_left {
    width: 100px;
  }
  .friends_right {
    left: 100px;
    width: calc(100% - 100px);
  }
  .friends_left .friends_left_header .small_circle {
    display: none;
  }
  .friends_left .mmenu_item span {
    display: none;
  }
  .header_icon {
    display: none;
  }
}
@media (max-width: 400px) {
  .friends_left_header h3 {
    font-size: 16px;
  }
  .see_link {
    font-size: 12px;
    width: 50px;
  }
  .friends_right {
    padding: 10px;
  }
}
