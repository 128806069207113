.dark {
  --bg-primary: #242526;
  --bg-secondary: #18191a;
  --bg-third: #3a3b3c;
  --bg-forth: #3a3b3c;
  --bg-fifth: #888888;
  --color-primary: #e4e6eb;
  --color-secondary: #b0b3b8;
}

.dark .blur {
  background: rgba(1, 1, 1, 0.53);
}

.dark .hover1:hover,
.dark .hover2:hover {
  background: var(--bg-third);
}

/* Header */
.dark header {
  background: var(--bg-primary);
}

.dark .header_right svg {
  fill: #fff;
}

.dark .all_right_circle i {
  filter: invert(100%);
}

.dark .active_header {
  background: var(--light-blue-color) !important;
}

.dark .active_header svg {
  fill: var(--blue-color) !important;
}

.dark .header_right .circle_icon:hover {
  background: var(--bg-fifth);
}

.dark input[type='text'] {
  color: var(--color-primary);
}

.dark .small_circle i,
.dark .small_circle svg,
.dark .white_circle svg,
.dark .circle i {
  filter: invert(100%) !important;
}

.dark .profile_link {
  color: var(--cool-white);
}

.dark .notifications_menu_icon {
  filter: invert(100%) !important;
}
/* Header End */

.dark .left_home .small_circle {
  background: var(--bg-fifth);
}

.dark .open_post:hover {
  background: #333;
}

.dark .post_action i {
  filter: invert(90%);
}

.dark .post_menu i,
.dark .post_menu img {
  filter: invert(100%);
}

.dark .comment_circle_icon i {
  filter: invert(95%);
}

.dark .comment_circle_icon:hover {
  background: #555;
}

.dark .box_privacy i,
.dark .box_privacy img {
  filter: invert(95%);
}

.dark .emoji_icon_large:hover {
  filter: invert(0);
}

.dark .add_pics_wrap i {
  filter: invert(100%);
}

/* Profile */
.dark .active_link:hover {
  background: var(--light-blue-color) !important;
}

.dark .active_link span {
  color: var(--cool-black);
}

.dark .open_cover_menu i,
.dark .open_cover_menu img {
  filter: invert(100%);
}

.dark .profile_circle i {
  filter: invert(100%);
}

.dark .gray_bttn {
  background: var(--bg-third);
}

.dark .gray_bttn:hover {
  background: #777;
}

.dark .gray_bttn i,
.dark .gray_bttn img {
  filter: invert(100%);
}

.dark .teal_bttn i,
.dark .teal_bttn img {
  filter: invert(100%);
}

.dark .profile_container svg {
  filter: invert(88%);
}

.dark .grid_icon {
  filter: invert(80%);
}

.dark .pictureBox i {
  filter: invert(100%) !important;
}

.dark .slider i {
  filter: invert(100%);
}

.dark .flex_p_t i {
  filter: invert(100%);
}

/* Home */
.dark .heading {
  color: #fff;
}

/* Buttons */
.dark .teal_bttn {
  border: none;
  outline: none;
  padding: 8.5px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px !important;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    var(--dark-teal),
    var(--teal),
    var(--light-teal)
  );
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.dark .open_signup {
  margin-top: 1rem;
  width: 70% !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  background: linear-gradient(to right, #f5ce62, #e43603, #fa8072, #e85a19);
  box-shadow: 0 1px 2px rgba(229, 66, 10, 0.75);
}

.dark .open_signup:hover {
  box-shadow: 0 2px 8px rgba(229, 66, 10, 0.75);
}

/* Friends */
.dark .m_group_icon {
  filter: invert(85%);
}
