.footer {
  height: 22vh;
  color: var(--cool-white);
  padding: 1.5rem;
  padding-top: 10%;
  display: flex;
  flex-direction: row; 
  justify-content: space-between; 
  display: flex;
}

.footer_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 3px 10px;
  font-size: 13px;
  max-width: 350px;
  margin: 0 auto;
}

.footer a {
  display: flex;
  align-items: center;
}

.footer a:hover {
  text-decoration: underline;
  color: var(--cool_white);
}

@media (max-height: 800px) {
  .footer {
    display: none;
  }
}