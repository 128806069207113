.input_wrap {
  position: relative;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input_wrap input {
  outline: none;
  border: 1px solid #e4e6eb;
  background: #fff;
  width: 100%;
  height: 50px;
  font-size: 17px;
  border-radius: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
  color: #050505 !important;
}

.input_wrap input:focus {
  border-color: var(--teal);
}

.input_error_border {
  border-color: #b94a48 !important;
}

.input_wrap i {
  position: absolute;
  right: 5px;
  top: 15px;
  transform: scale(0.8);
}

.input_error {
  position: relative;
  padding: 15px 10px;
  background: #b94a48;
  width: 100%;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.error_arrow_top {
  border-top: 10px solid #b94a48;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  bottom: -10px;
}

.error_arrow_bottom {
  border-bottom: 10px solid #b94a48;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -10px;
}

.input_error_desktop {
  position: absolute;
  width: 300px;
  left: -19.5rem;
  top: -3px;
}

.error_arrow_left {
  border-left: 10px solid #b94a48;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  right: -10px;
  top: 13px;
}
