.profile {
  color: var(--color-primary);
}

.profile_top {
  margin-top: 56px;
  box-shadow: 0 1px 2px var(--shadow-1);
  background: var(--bg-primary);
}

.profile_container {
  max-width: 945px;
  margin: 0 auto;
}

.profile_cover {
  position: relative;
  height: 350px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: var(--bg-secondary);
}

.profile_cover img {
  width: 100%;
  object-fit: cover;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.update_cover_wrapper {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.open_cover_update {
  background: #fff;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 14px;
  color: #111;
  border-radius: 10px;
  cursor: pointer;
}

.open_cover_update i {
  transform: scale(0.9);
  margin-top: 4px;
}

.open_cover_menu {
  background: var(--bg-primary);
  position: absolute;
  right: 0;
  padding: 10px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 12px 28px 0 var(--shadow-1), 0 2px 4px 0 var(--shadow-2),
    inset 0 0 0 px var(--shadow-inset);
  z-index: 9999;
}

.open_cover_menu_item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px;
}

.profile_img_wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: -2.8rem;
}

.profile_w_left {
  display: flex;
  gap: 1rem;
  padding: 1rem 13px 0 13px;
}

.profile_w_img {
  position: relative;
  /* z-index: 9999; */
}

.profile_w_bg {
  width: 180px;
  height: 180px;
  transform: translateY(-3.6rem);
  border-radius: 50%;
  background-repeat: no-repeat;
  border: 2px solid #fff;
  background: #fff;
  /* cursor: pointer; */
}

.profile_w_bg:hover {
  filter: brightness(95%);
}

.profile_circle {
  position: absolute;
  bottom: 4.5rem;
  right: 0.55rem;
  background: var(--bg-third);
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.profile_name {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 32px;
}

.profile_w_right {
  display: flex;
  gap: 8px;
  padding: 0 18px;
  margin-bottom: 7px;
  pointer-events: none !important;
}

.edit-bttn {
  border-radius: 4px !important;
  /* background: var(--teal) !important; */
}

.invert {
  filter: invert(100%);
}

/* Menu */
.profile_menu_wrap {
  position: relative;
  padding: 0 2.2rem;
}

.profile_menu {
  position: relative;
  border-top: 1px solid var(--border-color);
  display: flex;
  padding: 0;
}

.profile_menu a {
  height: 54px;
  width: 78.5px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 3px 0;
  color: var(--color-secondary);
}

.profile_menu_active {
  height: 60px !important;
  width: 65px !important;
  margin: 0 !important;
  color: var(--light-teal) !important;
  border-bottom: 3px solid var(--light-teal);
  border-radius: 0 !important;
}

.p10_dots {
  background: var(--bg-third);
  position: absolute;
  right: 0;
  top: 10px;
  border-radius: 5px;
  display: grid;
  place-items: center;
  padding: 8px 14px;
}

.p10_dots svg {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-left: 2px;
}

/* PplYouMayKnow */
.profile_bottom {
  margin-top: 1px;
  background: var(--bg-secondary);
}

.bottom_container {
  padding: 10px 2.2rem;
}

.pplumayknow_list {
  display: flex;
  gap: 5px;
  align-items: center;
  height: 240px;
}

.pplumayknow {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 3px solid var(--blue-color);
  padding: 10px 15px;
}

.pplumayknow_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
}

.addfriendCard {
  position: relative;
  height: 100%;
  width: 150px;
  border-radius: 10px;
  overflow: hidden;
  background: var(--bg-primary);
}

.addfriend_imgsmall {
  width: 100%;
  height: 140px;
}

.addfriend_imgsmall img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.addfriend_infos {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.addfriend_name {
  font-size: 16px;
  font-weight: 600;
}

.light_blue_btn img {
  width: 20px;
}

.pplumayknow_list .light_blue_btn {
  color: var(--blue-color);
}

.profile_grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  gap: 10px;
}
/* PplYouMayKnow End */

/* Menu */
.left_header_grid {
  font-size: 22px;
  font-weight: 600;
}

.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
  font-size: 14px;
}

.view_type {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 600;
  color: var(--color-secondary);
}

.grid2 .active {
  color: var(--blue-color);
}

.othername {
  color: var(--color-secondary);
  font-size: 20px;
}

.no_posts {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  color: var(--color-secondary);
}

/* Profile Card */
.profile_card {
  background: var(--bg-primary);
  border-radius: 10px;
  box-shadow: 0 1px 2px var(--shadow-1);
  padding: 10px 15px;
  margin-top: 15px;
}

.profile_card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
}

.profile_header_link {
  font-weight: normal;
  font-size: 11px;
  color: var(--light-teal);
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
}

.profile_header_link:hover {
  background: var(--bg-secondary);
}

.profile_card_count {
  color: var(--color-secondary);
}

.profile_card_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 10px;
}

.profile_photo_card {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.profile_photo_card img {
  width: 100%;
  height: 105px;
  object-fit: cover;
  border-radius: 10px;
}

.profile_photo_card span {
  color: var(--color-primary);
  font-size: 13px;
  padding-left: 1px;
}
.profile_photo_card:hover {
  filter: brightness(110%);
}

.profile_friend_count {
  font-weight: 600;
}

.profile_friend_imgs {
  display: flex;
  align-items: center;
}

.profile_friend_imgs img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 1px 2px var(--shadow-1);
}

.profile_friend_imgs img:hover {
  filter: brightness(110%);
}

.cover_cropper {
  height: 100%;
}

.cover_cropper img {
  object-fit: cover;
  width: 100%;
}

.cover_cropper .reactEasyCrop_Container {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.save_changes_cover {
  z-index: 999999999;
  position: fixed;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  font-size: 14px;
  color: var(--color-primary);
}

.save_changes_left {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
}

.save_changes_left i {
  filter: invert(100%);
}

.save_changes_right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.save_changes_right button {
  padding: 10px;
}

.save_changes_right button:first-of-type {
  width: 100px;
  background: #39393f;
}

.save_changes_right button:last-of-type {
  width: 160px;
}

.selectCoverBox {
  width: 570px !important;
  max-width: 80vw;
}

.selectCoverBox_links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 5px 15px;
}

.selectCoverBox_link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 0 10px 0;
  cursor: pointer;
}

.selectCoverBox_link:first-of-type {
  color: var(--light-teal);
  border-bottom: 3px solid var(--light-teal);
}

/* Post Loading Spinner */
.loading-posts {
  margin-left: 50px;
}

/* Animated Scroll Position Lock */
.scrollFixed .profile_left {
  position: fixed;
  width: 360px;
}

.scrollFixed .profile_right {
  margin-left: 370px;
  width: 504px;
}

.scrollFixed .profile_right .createPost {
  width: 504px;
}

.showLess .profile_left {
  bottom: 30px;
}

.showMore .profile_left {
  top: 64px;
}

/* Friendship */
.friendship {
  z-index: 9;
  display: flex;
  align-items: center;
  gap: 10px;
}

.friendship .teal_bttn {
  height: 34.25px;
}

.friends_menu_wrap {
  position: relative;
}

/* Button */
.teal_bttn i,
.teal_bttn img {
  filter: invert(100%);
}

@media (max-width: 1175px) {
  .send_verification,
  .createPost,
  .post {
    margin-left: 0px !important;
  }
  .send_verification {
    width: 100% !important;
  }
}
@media (max-width: 900px) {
  .profile_w_left {
    flex-direction: column;
    align-items: center;
  }
  .profile_w_col {
    transform: translateY(-3.6rem);
  }
  .profile_w_right {
    transform: translateY(-3.6rem);
  }
  .profile_img_wrap {
    flex-direction: column;
    justify-content: center;
  }
  .profile_circle {
    right: 1rem;
  }
  .profile_name {
    padding-bottom: 10px;
    flex-direction: column;
  }
  .pplumayknow {
    display: none;
  }
  .profile_grid {
    grid-template-columns: 1fr;
  }
  .profile_grid .createPost {
    width: 100% !important;
  }
  .bottom_container {
    padding: 5px;
  }
  .othername {
    transform: translateY(-10px);
  }
  .friendship {
    transform: translateY(-60px);
  }
  .profile_friend_count {
    text-align: center;
    transform: translateY(-15px);
  }
  .profile_friend_imgs {
    justify-content: center;
    transform: translateY(-10px);
  }
}
@media (max-width: 650px) {
  .profile_menu_wrap {
    display: none;
  }
}
@media (max-width: 510px) {
  .update_cover_wrapper {
    bottom: 3rem;
  }
  .profile_bottom {
    padding: 0px !important;
  }
}
@media (max-width: 405px) {
  .friendship {
    flex-direction: column;
  }
}
@media (max-width: 370px) {
    .open_cover_menu_item {
    margin-left: 18px !important;
  }
}
@media (min-width: 900px) {
  .friendship {
    transform: translateX(-2rem);
  }
}
