.left_home {
  position: fixed;
  margin-top: 58px;
  left: 6px;
  max-height: 100vh;
  width: 15vw;
  overflow-y: auto;
  color: var(--color-primary);
  padding: 15px 0;
  padding-bottom: 5rem;
  user-select: none;
}

.left_link {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
}

.left_link img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 11px;
  vertical-align: -0.25em;
}

.left_link span {
  margin-bottom: 4px;
  margin-left: 1px;
}

.col {
  margin-left: 2px;
  transform: translateY(-2px);
}

.col_1 {
  transform: translateX(-1px);
}

.col_2 {
  position: relative;
  font-size: 12px;
  color: var(--blue-color);
  margin-left: 9px;
  margin-top: 2px;
  font-weight: normal;
}

.col_2::before {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--blue-color);
  position: absolute;
  left: -10px;
  bottom: 2px;
}

.rotate360 {
  transform: rotate(180deg);
}

.splitter {
  height: 2px;
  background: var(--light-teal);
  margin: 9px 2px 0 12px;
  box-shadow: 0 2px 4px var(--shadow-1);
}

.shortcut {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-secondary);
  padding: 13px 10px 11px 10px;
}

.edit_shortcut {
  font-size: 14px;
  color: var(--blue-color);
  display: none;
  cursor: pointer;
  padding-top: 10px;
  margin-right: 27px;
}

.shortcut:hover .edit_shortcut {
  display: block;
}

.shortcut_item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-primary);
}

.shortcut_item img {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  object-fit: cover;
}

.shortcut_item span {
  margin-bottom: 3px;
}

.rooster_copyright {
  position: fixed;
  bottom: 14px;
  left: 10px;
  font-size: 12px;
  color: var(--color-secondary);
  width: 15vw;
}

.relative_rooster_copyright {
  position: relative;
  width: 97%;
  margin-top: 2rem;
}
