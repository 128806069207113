* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
}

html {
  overflow-y: scroll;
}

a {
  text-decoration: none;
  color: inherit;
}

:root {
  --bg-primary: #fff;
  --bg-secondary: #f0f2f5;
  --bg-third: #e4e6eb;
  --bg-forth: #f0f2f5;
  --color-primary: #050505;
  --color-secondary: #65676b;
  --divider: #ced0d4;
  --dark-bg-primary: #18191a;
  --dark-bg-secondary: #242526;
  --dark-bg-third: #3a3b3c;
  --dark-color-primary: #242526;
  --dark-color-secondary: #b0b3b8;
  --blue-color: #1876f2;
  --green-color: #42b72a;
  --light-blue-color: #e7f3ff;
  --border-color: #ccced2;
  --shadow-1: rgba(0, 0, 0, 0.2);
  --shadow-2: rgba(0, 0, 0, 0.1);
  --shadow-3: rgba(0, 0, 0, 0.3);
  --shadow-inset: rgba(255, 255, 255, 0.5);

  --cool-black: rgb(21, 25, 34);
  --cool-gray: #8c92ac;
  --cool-white: rgb(244, 253, 255);
  --coral: #db3600;
  --dark-teal: #023f51;
  --error-red: #b94a48;
  --form-grey: rgba(0, 0, 0, 0.25);
  --grey: rgb(112, 112, 112);
  --light-grey: #d3d3d3;
  --light-teal: rgb(12, 177, 199);
  --midnite: #041e2b;
  --pm-black: #050505;
  --rooster-red: #ff0000;
  --rooster-divider: linear-gradient(
    to top,
    #041e2b,
    #023f51,
    #00829a,
    #0cb1c7
  );
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
  --teal: rgb(0, 130, 154);
  --white-smoke: rgb(245, 245, 245);
}

.blur {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 999999999999;
}

/* Default Button Configs */
.teal_bttn {
  border: none;
  outline: none;
  padding: 8.5px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    var(--dark-teal),
    var(--teal),
    var(--light-teal)
  );
  box-shadow: 0 1px 2px var(--dark-teal);
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.teal_bttn:hover {
  background-position: 100% 0;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 3px 8px rgba(49, 196, 190, 0.75);
}

.open_signup {
  margin-top: 1rem;
  width: 70% !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  background: linear-gradient(to right, #f5ce62, #e43603, #fa8072, #e85a19);
  box-shadow: 0 1px 2px rgba(229, 66, 10, 0.75);
}

.open_signup:hover {
  box-shadow: 0 2px 8px rgba(229, 66, 10, 0.75);
}

.gray_bttn {
  border: none;
  outline: none;
  background: var(--bg-secondary);
  box-shadow: 0 1px 2px var(--shadow-1);
  padding: 8.5px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}

.light_blue_btn {
  border: none;
  outline: none;
  background: var(--light-blue-color);
  padding: 8.5px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}

.teal_bttn,
.gray_bttn span {
  transform: translateY(-1px);
}

/* Default Button Configs End */

/* Hovers */
.hover1:hover {
  background: var(--bg-secondary);
}

.hover2:hover {
  background: var(--bg-third);
}

.hover3:hover {
  background: #d4d6da3a;
}
/* Hovers End */

.card {
  height: 500px;
  width: 500px;
  background: red;
}

/* Scrollbar */
.scrollbar::-webkit-scrollbar {
  width: 8px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: #aeaeafa1;
  border-radius: 5px;
}

/* Circles */
.small_circle {
  position: relative;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
  background: var(--bg-third);
  cursor: pointer;
}

/* Filters */
.filter_blue {
  filter: invert(39%) sepia(57%) saturate(200%) saturate(200%) saturate(200%)
    saturate(200%) saturate(200%) saturate(147.75%) hue-rotate(202deg)
    brightness(97%) contrast(96%);
}

